<template lang="pug">
div.slider-container
  .slider-button-group
    div(:class="wrapperClass+'button-prev'").slider-button
      i.far.fa-long-arrow-left.fa-2x
    div(:class="wrapperClass+'button-next'").slider-button
      i.far.fa-long-arrow-right.fa-2x
  .slider(:id="wrapperClass")
    slot
    slot(name="containerContent")
</template>

<script>
import Swiper, { Navigation } from 'swiper';

export default {
  name: 'Slider',
  props: {
    options: {
      type: Object,
      default: () => ({
        wrapperClass: 'slider-wrapper',
        slideClass: 'slider-slide',
      }),
    },
  },
  data() {
    return {
      slider: null,
    };
  },
  computed: {
    wrapperClass() {
      return this.options.wrapperClass;
    },
  },
  mounted() {
    Swiper.use([Navigation]);
    this.slider = new Swiper(`#${this.wrapperClass}`, {
      ...this.options,
      navigation: {
        nextEl: `.${this.wrapperClass}button-next`,
        prevEl: `.${this.wrapperClass}button-prev`,
      },
      on: {
        slideChange: () => {
          this.$emit('slide-change', this.slider ? this.slider.realIndex : null);
        },
        beforeResize: () => {
          this.slider.slides.css('width', '');
        },
      },
    });

    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  },
};
</script>

<style lang="scss">
@import '~swiper/swiper-bundle';

// helper classes for slider childs

.slider {
  position: relative;
  display: flex;
  overflow: hidden;
}

.slider-container {
  display: flex;
}

.slider-wrapper {
  position: relative;
  display: flex;
  width: 100%;
}

.slider-slide {
  flex-shrink: 0;
}

.slider-container {
  position: relative;
}

.slider-button-group {
  display: flex;
  justify-content: center;
  position: absolute;
  top: -96px;
  right: calc((100% - 1300px) / 2);

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }

}

.slider-button{
  margin: 0;
  color: $hunterGreen;
  cursor: pointer;
  background: $hunterGreen;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: $white;
  font-size: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.swiper-button-disabled{
  cursor: auto;
  opacity: 0.35;
}
</style>
